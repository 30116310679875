<template>
  <div>
    <v-row>
      <v-col sm="6">
        <v-card outlined rounded>
          <v-card-title> {{ $t('report.finance') }} </v-card-title>
          <v-card-text>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item @click="link('report_salary')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('report.reportsalary') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="link('reportcash')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('report.reportcash') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="6">
        <v-card outlined rounded>
          <v-card-title> {{ $t('report.statistic') }} </v-card-title>
          <v-card-text>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item @click="link('report_statistic')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('report.reportstat') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="link('report_smena')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('report.reportsmena') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="link('report_service')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('report.reportservice') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="link('report_worker')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('report.reportworker') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="link('reportgoodtrash')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('report.reportgoodtrash') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {},
  components: {},
  watch: {},
  mounted: function () {},
  methods: {
    link: function (view) {
      this.$router.push(view)
    },
  },
}
</script>

<style></style>
